import React, { useEffect } from "react";
import { Button, Img, Line, List, Text } from "../../components";
import { useLocation, useNavigate } from "react-router-dom";
import { Swiper } from "react-vant";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  IconButton,
  Link,
  Menu,
  MenuItem,
  Snackbar
} from "@mui/material";
import queryString from 'query-string';


function handleScroll(ref: React.RefObject<HTMLDivElement>): void {
  if (ref && ref.current) {
    ref.current.scrollIntoView({ behavior: "smooth" , block:"start", inline:"start"});
  }
}

let willScrollRef: String = null;


const MobileAgate: React.FC = () => {
  const search = useLocation().search;
  const device =  search?queryString.parse(search):{"device":""};
  const isApp = device["device"] === "app";
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [expanded, setExpanded] = React.useState<string | false>('panel1');
  const [showTips, setShowTips] = React.useState(false);

  const open = Boolean(anchorEl);

  const page1 =
    <div className={"bg-gradient1 flex h-[700px] relative w-full"}>
      <Img
        className={"absolute h-[100] w-full inset-[0] justify-center m-auto object-fill max-w-[100vw] opacity-[0.15]"}
        src={"images_mobile/img_1609m00i125n001sc12.png"}
        alt="1609m00i125n001"
      />
      <Img
        className="absolute top-[78px] h-[100] inset-x-[0] mx-auto w-full"
        src={"images_mobile/img_shapes.svg"}
        alt="shapes"
      />
      <div className="absolute  bottom-[0] h-[261px] w-full">
        <Img
          className="absolute bottom-[0] w-[375px] h-[79px] object-cover"
          src={"images_mobile/img_circlebg.svg"}
          alt="circlebg"
        />
        <div className="h-full w-[152px] m-auto">
          <Img
            className="absolute justify-center bottom-[0] object-cover w-[152px]"
            src={"images/img_frame25.png"}
            alt="frameTwentyFive"
          />
        </div>
      </div>
      <div
        className="absolute flex flex-col md:gap-10 gap-[136px] h-full inset-[0] items-center justify-center m-auto pt-[100px] w-full">
        {/*<Header*/}
        {/*  className="flex md:flex-col flex-row md:gap-10 items-center justify-between md:px-10 sm:px-5 px-[150px] w-full" />*/}
        <div className=" md:h-[700px] h-[700px] relative w-full">

          <div
            className="absolute flex flex-col gap-[24px] items-center justify-start left-[0] max-w-[1100px] md:px-6 sm:px-2 px-[150px] top-[0] w-full">
            <Text
              className="leading-[140.00%] md:text-[28px] text-[64px] text-white-A700 text-center"
              size="txtInterSemiBold64"
            >
              A Project<br />
              aimed at making Internet<br />
              beneficial for all
            </Text>
            <Text
              className="leading-[150.00%] md:text-[16px] text-white-A700_cc text-center"
              size="txtInterSemiBold24"
            >
              Σ–Network is an blockchain online ecosystem where everyone can share the benefits that Internet brings to the world.
            </Text>
            <Button
              className="cursor-pointer font-medium h-[50px] min-w-[200px] rounded-[25px] text-center text-[16px]"
              size="md"
              variant="gradient"
              color="red_A100_red_500"
              onClick={() => {
                handleClick("whitePaper");
              }}
            >
              Σ–Network Whitepaper
            </Button>
          </div>
        </div>
      </div>
    </div>;

  const page2 = <div className="bg-blue-A700 flex flex-col items-center justify-end pt-[32px] w-full">

    {/*<Header className="flex md:flex-col flex-row md:gap-10 items-center justify-between md:px-5 px-[150px] w-full" />*/}
    <div className="h-[700px] md:h-[700px] mt-1 md:px-5 relative w-full">
      <div className="absolute flex flex-col h-full inset-y-[0] items-center justify-start my-auto right-[0] w-full">
        <div className="h-[353px] top-[45%] relative w-full">
          <Img
            className="absolute ml-[37px] mt-[120px] my-auto h-[131px] w-[131px] rotate-[-75]"
            src={"images/img_group546.svg"}
            alt="group546"
          />
          <div className="absolute inset-[0] justify-center m-auto w-full">
            <Img
              className="h-full m-auto object-cover w-[375px]"
              src={"images_mobile/img_employeesworki.png"}
              alt="employeesworki"
            />
            <div className="absolute md:h-10 h-[33px] right-[4.5%] top-[46%] w-[144px]">
              <div className="absolute h-9 inset-x-[0] mx-auto top-[0] w-full">
                <div
                  className="absolute bg-white-A700 h-[33px] inset-[0] justify-center m-auto rotate-[180deg] rounded-bl-[5px] rounded-tl-[5px] rounded-br-[5px] w-full"></div>
                <div
                  className="absolute flex flex-row gap-[7px] h-full inset-y-[0] items-center justify-start ml-[3px] my-auto w-[136px]">
                  <div className="flex flex-col h-6 items-center justify-start w-6">
                    <Img
                      className="h-6 md:h-auto rounded-[50%] w-6"
                      src={"images/img_attractivefema.png"}
                      alt="attractivefema"
                    />
                  </div>
                  <Text
                    className="leading-[140.00%] text-gray-900 text-[6px] "
                    size="txtInterRegular14"
                  >
                    hello, I have a great idea about
                    Σ–Network project!
                  </Text>
                </div>
              </div>
              <div className="absolute bg-red-A200_01 bottom-[0] h-2 left-[27%] rounded-[50%] w-2"></div>
            </div>
            <div className="absolute bottom-[16%] md:h-[38px] h-[35px] left-[4%] w-[128px]">
              <div
                className="absolute bg-white-A700 bottom-[0] h-[33px] inset-x-[0] mx-auto rounded-bl-[5px] rounded-tl-[5px] rounded-tr-[5px] w-[97%]"></div>
              <div
                className="absolute bottom-[5px] flex flex-row gap-[7px] inset-x-[0] items-center justify-start mx-auto w-[115px]">
                <Text
                  className="leading-[140.00%] text-gray-900 text-[6px] w-[78%] sm:w-full"
                  size="txtInterRegular14"
                >
                  I think Σ–Network is the best project ever!
                </Text>
                <div className="flex flex-col h-6 items-center justify-start w-6">
                  <Img
                    className="md:h-auto rounded-[50%]"
                    src={"images/img_attractivefema_44x44.png"}
                    alt="attractivefema_One"
                  />
                </div>
              </div>
              <div className="absolute bg-teal-A700 h-2 left-[8px] rotate-[164deg] rounded-[50%] top-[0] w-2"></div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="absolute flex flex-col gap-[25px] items-center justify-start left-[0] md:px-6 sm:px-5 px-[150px] top-[10%] w-full">
        <Text
          className="leading-[140.00%] md:text-[28px] text-[64px] text-white-A700 text-center"
          size="txtInterSemiBold64"
        >
          A Community<br />
          owned and governed<br />
          by all its members
        </Text>
        <Text
          className="leading-[150.00%] text-2xl md:text-[16px] text-white-A700_cc text-center"
          size="txtInterSemiBold24"
        >
          <>
            Join the vibrant Σ–Network Community to participate in project discussions
            and get the latest updates.
          </>
        </Text>
        <Button
          className="cursor-pointer flex items-center justify-center h-[50px] min-w-[212px] rounded-[25px] text-center text-[16px]"
          leftIcon={
            <Img
              className="h-[30px] mr-2.5"
              src={"images/img_telegram_light_blue_200_01.svg"}
              alt="telegram"
            />
          }
          size="md"
          variant="gradient"
          color="red_A100_red_500"
          onClick={() => {
            handleClick("Community");
          }}
        >
          <div className="font-medium text-center text-lg">
            Σ–Network Community
          </div>
        </Button>
      </div>
    </div>
  </div>;

  const page3 = <div
    className="bg-cover bg-indigo-900 bg-no-repeat flex flex-col gap-[31px] h-[700px] items-start justify-end pt-6 w-full"
    style={{
      backgroundImage: "url('images_mobile/img_background_blue_gray_600.svg')"
    }}
  >
    {/*<Header className="flex md:flex-col flex-row md:gap-10 items-center justify-between md:px-5 px-[150px] w-full" />*/}
    <div className="md:px-5 relative w-[90%] md:w-full h-full">
      <div className="absolute flex flex-col h-full top-[367px] ml-[50px] w-[240px]">
        <Img
          className="md:h-auto object-cover w-[588px]"
          src={"images_mobile/img_smilinghappyy.png"}
          alt="smilinghappyy"
        />
      </div>
      <div
        className="absolute flex flex-col gap-[25px] items-center justify-start left-[0] max-w-[375px] md:px-10 sm:px-2 px-[150px] top-[11%] w-full">
        <Text
          className="leading-[140.00%] md:text-[32px] text-[32px] text-white-A700 text-center"
          size="txtInterSemiBold64"
        >
          <>
            A Coin<br />
            with high liquidity and <br />
            substantial prospects
          </>
        </Text>
        <Text
          className="leading-[150.00%] max-w-[800px] md:max-w-full text-[16px] md:text-[16px] text-white-A700_cc sm:text-[16px] text-center"
          size="txtInterSemiBold24"
        >
          Σ–Network is the basic value unit of the Σ–Network
          and is used to measure user
          contributions and calculate user benefits.
        </Text>
        <div
          className="bg-gradient  flex flex-col h-[50px] md:h-auto items-center justify-center px-4 py-2.5 rounded-[25px] w-auto"
          onClick={() => {
            handleClick("toMine");
          }}
        >
          <Text
            className="text-base text-center text-white-A700 w-auto"
            size="txtInterMedium16"
          >
            How to Earn Σ–Network?
          </Text>
        </div>
      </div>
    </div>
  </div>;

  const agatesRef = React.createRef<HTMLDivElement>();
  const ecosystemRef = React.createRef<HTMLDivElement>();
  const agatRef = React.createRef<HTMLDivElement>();
  const roadmapRef = React.createRef<HTMLDivElement>();
  const contactRef = React.createRef<HTMLDivElement>();
  const earnAgatRef = React.createRef<HTMLDivElement>();

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(()=>{
    switch (willScrollRef) {
      case "About":
        handleScroll(agatesRef);
        break;
      case "Ecosystem":
        handleScroll(ecosystemRef);
        break;
      case "Σ–Network":
        handleScroll(agatRef);
        break;
      case "Roadmap":
        handleScroll(roadmapRef);
        break;
      case "Contact":
        handleScroll(contactRef);
        break;
    }
    willScrollRef = null;
  }, [willScrollRef]);

  const delayedScroll = (refElement: String)=>{
    willScrollRef = refElement;
  };

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  const handleClick = (event: String) => {
    switch (event) {
      case "About":
        handleClose();
        delayedScroll(event);
        break;
      case "Ecosystem":
        handleClose();
        delayedScroll(event);
        break;
      case "Σ–Network":
        handleClose();
        delayedScroll(event);
        break;
      case "Roadmap":
        handleClose();
        delayedScroll(event);
        break;
      case "Contact":
        handleClose();
        delayedScroll(event);
        break;
      case "Install":
        handleClose();
        setShowTips(true);
        // handleScroll(contactRef);
        break;
      case "toMine":
        handleScroll(earnAgatRef);
        break;
      case "whitePaper":
        navigate(isApp?"/md/whitepaper?device=app":"/md/whitepaper");
        break;
      case "Community":
        window.open("about:blank").location.href = "https://t.me/Σ–NetworkCommunity";
        break;
      case "Notice":
        window.open("about:blank").location.href = "https://t.me/Σ–Network";
        break;
      case "Twitter":
        window.open("about:blank").location.href = "https://twitter.com/Σ–Network";
        break;
      case "Discord":
        // window.open("about:blank").location.href = "https://twitter.com/AgatesNetwork";
        break;
      case "FaceBook":
        // window.open("about:blank").location.href = "https://www.facebook.com/profile.php?id=61556685444967";
        break;
      case "Service":
        window.open("about:blank").location.href = "https://t.me/Σ–NetworkCommunity";
        break;
    }
  };

  return (
    <>
      <div className={"bg-white-A700 flex flex-col font-inter items-center justify-start mx-auto pb-[92px] w-full"}>
        <div className="flex flex-col items-start justify-start max-w-[375px] w-full">
          <div className={"bg-amber-500 relative flex flex-col items-center justify-center w-full h-[700px]"}>
            <Swiper className={"absolute w-full h-full"} autoplay={3000} touchable={true}>
              <Swiper.Item>
                {page1}
              </Swiper.Item>
              <Swiper.Item>
                {page2}
              </Swiper.Item>
              <Swiper.Item>
                {page3}
              </Swiper.Item>
            </Swiper>
            <div
              className={"absolute flex flex-row items-center justify-between w-[90%] h-[56px]" + (isApp ? " top-[30px]" : " top-[10px]")}>
              <div className={"flex flex-row items-center gap-2"}>
                {isApp ? <></> : <div className="flex flex-col h-8 items-center justify-start rounded-lg w-8">
                  <Img
                    className="h-8 md:h-auto object-cover rounded-lg w-8"
                    src={"images/img_logo.png"}
                    alt="logo_One"
                  />
                </div>}
                <Text className={"text-base text-center text-white-A700 w-auto"}
                      size="txtInterMedium16"
                >
                  Σ–Network
                </Text>
              </div>
              <IconButton onClick={(event) => {
                setAnchorEl(event.currentTarget);
              }}
                          aria-controls={open ? 'home-menu' : undefined}
                          aria-haspopup="true"
                          aria-expanded={open ? 'true' : undefined}>
                <Img src={"images_mobile/ic_menu.svg"} alt="icMenu" />
              </IconButton>
            </div>
            <Snackbar
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
              open={showTips}
              onClose={() => {
                setShowTips(false)
              }}
              message="Σ–Network App will soon be released on App Store and Google Play."
              key={"top center"}
            />
            <Menu
              anchorEl={anchorEl}
              id={"home-menu"}
              open={open}
              onClose={handleClose}
              // onClick={handleClose}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
              <MenuItem onClick={() => {
                handleClick("About")
              }}>
                About Σ–Network
              </MenuItem>
              <MenuItem onClick={() => {
                handleClick("Ecosystem")
              }}>
                Ecosystem
              </MenuItem>
              <MenuItem onClick={() => {
                handleClick("AGAT")
              }}>
                Σ–Network
              </MenuItem>
              <MenuItem onClick={() => {
                handleClick("Roadmap")
              }}>
                Roadmap
              </MenuItem>
              <MenuItem onClick={() => {
                handleClick("Contact")
              }}>
                Contact
              </MenuItem>
              {isApp ? <></> : <MenuItem onClick={() => {
                handleClick("Install")
              }}>
                Install App
              </MenuItem>}
            </Menu>
          </div>
          <div className="flex flex-col gap-6 items-center justify-start md:px-10 sm:px-5 px-[70px] py-14 w-full"
               ref={agatesRef}>
            <Text
              className="text-5xl sm:text-[32px] md:text-[40px] text-center text-gray-900 w-full"
              size="txtInterSemiBold48"
            >
              What is<br />
              Σ–Network?
            </Text>
            <Text
              className="leading-[170.00%] text-2xl md:text-[16px] text-gray-900_a2 sm:text-lg text-center"
              size="txtInterRegular24"
            >
              Σ–Network is committed to leveraging cutting-edge
              blockchain technology to create a vast internet ecosystem with a
              billion users, spanning e-commerce, digital content, social,
              entertainment, online game, and more. <br />
              <br />
              Σ–Network upholds the values of Equality of All, Fair
              Return, and Harmony Throughout the World, aspiring to ensure
              that users worldwide can partake in the benefits brought by the
              internet technology.{" "}
            </Text>
          </div>

          <div className="h-[466px] relative w-full">
            <div className="absolute h-[466px] inset-[0] justify-center m-auto w-full">
              <div className="bg-blue-A700_93 h-[466px] m-auto w-full"></div>
              <Img
                className="absolute h-[77px] object-cover right-[0] top-[0]"
                src={"images_mobile/img_shapes_amber_a200.svg"}
                alt="shapes_One"
              />
            </div>
            <div
              className="absolute flex flex-col gap-5 items-start justify-start max-w-[375px] md:px-6 sm:px-3.5 px-[100px] top-[11%] w-full">
              <Text
                className="leading-[140.00%] text-5xl sm:text-[32px] md:text-[40px] text-white-A700 text-center w-full"
                size="txtInterSemiBold48WhiteA700"
              >
                Global Access<br />
                at Zero Expense
              </Text>
              <Text
                className="leading-[140.00%] max-w-[370px] md:max-w-full text-2xl md:text-[16px] text-white-A700_cc sm:text-lg text-center"
                size="txtInterRegular24WhiteA700a2"
              >
                Σ–Network extends an open invitation to individuals across the globe, offering unrestricted access
                without financial barriers, thereby allowing users worldwide to participate freely in its blockchain
                ecosystem.
              </Text>
              <div className="flex flex-row items-center justify-between py-2.5 w-full">
                <Img
                  className="h-[51px] md:h-auto object-cover w-[161px]"
                  src={"images/img_appstore.png"}
                  alt="appstore"
                  onClick={() => {
                    handleClick("Install")
                  }}
                />
                <Img
                  className="h-[51px] md:h-auto object-cover w-[161px]"
                  src={"images/img_playstore.png"}
                  alt="playstore"
                  onClick={() => {
                    handleClick("Install")
                  }}
                />
              </div>
            </div>
          </div>

          <div
            className="bg-deep_purple-A400_05 flex flex-col gap-6 items-center justify-start md:px-10 sm:px-5 px-[150px] py-12 w-full">
            <Text
              className="text-5xl sm:text-[32px] md:text-[40px] text-center text-gray-900 w-full"
              size="txtInterSemiBold48"
            >
              Why is <br /> Σ–Network <br /> Unique?
            </Text>
            <Text
              className="text-2xl md:text-[16px] text-center text-gray-900_a2 sm:text-lg w-full"
              size="txtInterRegular24"
            >
              Σ–Network has an innovative business model, advanced
              technical architecture and lofty ideals.
            </Text>
            <div
              className="gap-4 flex flex-col items-start justify-between max-w-[1300px] mx-auto w-full">
              <div
                className="bg-white-A700 flex flex-1 flex-col gap-4 h-[404px] md:h-auto items-center justify-start px-1 py-4 rounded-[10px] w-full">
                <Img
                  className="h-20 w-[72px]"
                  src={"images/img_icondistribution.svg"}
                  alt="icondistributio"
                />
                <div className="flex flex-col gap-4 items-center justify-start">
                  <Text
                    className="leading-[140.00%] text-2xl md:text-[20px] sm:text-xl text-center text-gray-900 w-full"
                    size="txtInterSemiBold24Gray900"
                  >
                    Rich Business Ecology
                  </Text>
                  <Text
                    className="leading-[160.00%] text-center text-gray-900_99 text-lg w-full"
                    size="txtInterRegular20"
                  >
                    Σ–Network provides a wide range of application scenarios for its Σ–Network token through an
                    ecosystem including e-commerce, consumer finance, etc.
                  </Text>
                </div>
              </div>
              <div
                className="bg-white-A700 flex flex-1 flex-col gap-4 h-[404px] md:h-auto items-center justify-start px-1 py-4 rounded-[10px] w-full">
                <Img
                  className="h-20 w-[72px]"
                  src={"images/img_close.svg"}
                  alt="close"
                />
                <div className="flex flex-col gap-4 items-center justify-start">
                  <Text
                    className="leading-[140.00%] text-2xl md:text-[20px] text-center text-gray-900 sm:text-xl w-full"
                    size="txtInterSemiBold24Gray900"
                  >
                    Considerable Mining Rewards
                  </Text>
                  <Text
                    className="leading-[160.00%] text-center text-gray-900_99 text-lg w-full"
                    size="txtInterRegular20"
                  >
                    Each user can obtain mining rewards, shareholder dividends, partner dividends and other rewards from
                    the growth of the Σ–Network.
                  </Text>
                </div>
              </div>
              <div
                className="bg-white-A700 flex flex-1 flex-col gap-4 h-[404px] md:h-auto items-center justify-start px-1 py-4 rounded-[10px] w-full">
                <Img
                  className="h-20 w-[72px]"
                  src={"images/img_iconpartner.svg"}
                  alt="iconpartner"
                />
                <Text
                  className="text-2xl md:text-[20px] text-center text-gray-900 sm:text-xl w-full"
                  size="txtInterSemiBold24Gray900"
                >
                  Free Circulating Σ–Network
                </Text>
                <Text
                  className="leading-[160.00%] max-w-[292px] md:max-w-full text-center text-gray-900_99 text-lg"
                  size="txtInterRegular20"
                >
                  Free circulation is driving widespread holdings of Σ–Network, as well as public recognition of its value,
                  providing it with appreciation potential.
                </Text>
              </div>
              <div
                className="bg-white-A700 flex flex-1 flex-col gap-4 h-[404px] md:h-auto items-center justify-start px-1 py-4 rounded-[10px] w-full">
                <Img
                  className="h-20 w-[72px]"
                  src={"images/img_iconsafety.svg"}
                  alt="iconsafety"
                />
                <div className="flex flex-col gap-4 items-center justify-start">
                  <Text
                    className="leading-[140.00%] text-2xl md:text-[20px] text-center text-gray-900 sm:text-xl w-full"
                    size="txtInterSemiBold24Gray900"
                  >
                    Absolute Account Security
                  </Text>
                  <Text
                    className="leading-[160.00%] text-center text-gray-900_99 text-lg w-full"
                    size="txtInterRegular20"
                  >
                    Σ–Network ensures the security of accounts and assets through various methods including PIN
                    codes, mnemonic phrases, and biometric identification.
                  </Text>
                </div>
              </div>
            </div>
          </div>

          <div
            className="flex-col gap-5 inset-y-[0] items-center justify-start max-w-[375px] my-auto md:px-5 sm:px-5 py-10 right-[0] w-full"
            ref={ecosystemRef}>

            <div
              className="relative md:h-[370px] h-[420px] pb-[42px] md:px-10 sm:px-5 px-[42px] mb-[10px] w-[49%] md:w-full">
              <div
                className="absolute bg-gradient2  h-[274px] inset-[0] justify-center m-auto rotate-[120deg] rounded-[50%] w-[274px]"></div>
              <Img
                className="absolute md:h-[369px] h-[419px] inset-x-[0] mx-auto object-cover top-[0] md:w-[184px] w-[209px]"
                src={"images/img_shot_store.png"}
                alt="imageShotStore"
              />
            </div>

            <Text
              className="leading-[140.00%] text-5xl sm:text-[32px] md:text-[40px] text-gray-900 text-center"
              size="txtInterSemiBold48"
            >
              <>
                What's in the<br />
                Σ–Network <br /> Ecosystem?
              </>
            </Text>
            <Text
              className="leading-[150.00%] max-w-[375px] md:max-w-full text-2xl md:text-[16px] text-gray-900_a2 sm:text-lg"
              size="txtInterRegular24"
            >
              The business ecosystem of Σ–Network aims to create a
              practical and innovative ecosystem to provide Σ–Network with rich
              application scenarios.
            </Text>
            <div className="flex flex-col gap-10 md:gap-5 items-start justify-between py-10 w-full ">
              <div
                className="bg-white-A700 flex flex-1 flex-row gap-4 h-[264px] md:h-auto items-start justify-start px-1 w-full">
                <Img
                  className="h-[60px] w-12"
                  src={"images/img_icon_blue_a700_01.svg"}
                  alt="icon"
                />
                <div className="flex flex-1 flex-col gap-1.5 items-start justify-start w-full">
                  <Text
                    className="text-2xl md:text-[22px] text-gray-900 sm:text-xl w-full"
                    size="txtInterSemiBold24Gray900"
                  >
                    Σ–Network Store
                  </Text>
                  <Text
                    className="leading-[160.00%] max-w-[288px] md:max-w-full text-gray-900_99 text-xl"
                    size="txtInterRegular20"
                  >
                    Σ–Network Store allows users to earn Σ–Network by shopping and
                    later use Σ–Network to purchase items from merchants that
                    receive Σ–Network.
                  </Text>
                </div>
              </div>
              <div
                className="bg-white-A700 flex flex-1 flex-row gap-4 h-[264px] md:h-auto items-start justify-start px-1 w-full">
                <Img
                  className="h-[60px] w-12"
                  src={"images/img_icon_orange_500.svg"}
                  alt="icon"
                />
                <div className="flex flex-1 flex-col gap-1.5 items-start justify-start w-full">
                  <Text
                    className="text-2xl md:text-[22px] text-gray-900 sm:text-xl w-full"
                    size="txtInterSemiBold24Gray900"
                  >
                    Σ–Network Community
                  </Text>
                  <Text
                    className="leading-[160.00%] max-w-[288px] md:max-w-full text-gray-900_99 text-xl"
                    size="txtInterRegular20"
                  >
                    Σ–Network Community combines social interaction with
                    digital content, allowing creators to earn Σ–Network while
                    sharing their creativity, and earn profits by trading
                    digital collections.
                  </Text>
                </div>
              </div>
              <div className="bg-white-A700 flex flex-1 flex-row gap-4 items-start justify-start px-1 w-full">
                <Img
                  className="h-[60px] w-12"
                  src={"images/img_icon_green_a700.svg"}
                  alt="icon"
                />
                <div className="flex flex-1 flex-col gap-1.5 items-start justify-start w-full">
                  <Text
                    className="text-2xl md:text-[22px] text-gray-900 sm:text-xl w-full"
                    size="txtInterSemiBold24Gray900"
                  >
                    Σ–Network Entertain
                  </Text>
                  <Text
                    className="leading-[160.00%] max-w-[288px] md:max-w-full text-gray-900_99 text-xl"
                    size="txtInterRegular20"
                  >
                    Σ–Network Entertain is committed to meeting the needs of
                    users, and uses Σ–Network as a medium to encourage the
                    creation, sharing and circulation of entertainment
                    content.
                  </Text>
                </div>
              </div>
              <div
                className="bg-white-A700 flex flex-1 flex-row gap-4 h-[264px] md:h-auto items-start justify-start px-1 w-full">
                <Img
                  className="h-[60px] md:h-auto object-cover w-12"
                  src={"images/img_icon_white_a700.png"}
                  alt="icon"
                />
                <div className="flex flex-1 flex-col gap-1.5 items-start justify-start w-full">
                  <Text
                    className="text-2xl md:text-[22px] text-gray-900 sm:text-xl w-full"
                    size="txtInterSemiBold24Gray900"
                  >
                    Σ–Network Game
                  </Text>
                  <Text
                    className="leading-[160.00%] max-w-[288px] md:max-w-full text-gray-900_99 text-xl"
                    size="txtInterRegular20"
                  >
                    Σ–Network Game is not only a game platform, but also a
                    digital, social, and commercial comprehensive
                    entertainment experience, with Σ–Network integrated into
                    it.
                  </Text>
                </div>
              </div>
              <div
                className="bg-white-A700 flex flex-1 flex-row gap-4 h-[264px] md:h-auto items-start justify-start px-1 w-full">
                <Img
                  className="h-[60px] w-12"
                  src={"images/img_icon_blue_a700_01_60x48.svg"}
                  alt="icon"
                />
                <div className="flex flex-1 flex-col gap-1.5 items-start justify-start w-full">
                  <Text
                    className="text-2xl md:text-[22px] text-gray-900 sm:text-xl w-auto"
                    size="txtInterSemiBold24Gray900"
                  >
                    Σ–Network Tools
                  </Text>
                  <Text
                    className="leading-[160.00%] max-w-[288px] md:max-w-full text-gray-900_99 text-xl"
                    size="txtInterRegular20"
                  >
                    Σ–Network Tools provides users with rich, easy-to-use,
                    ad-free mobile applications to help users use the
                    Internet in a simpler and smarter way.
                  </Text>
                </div>
              </div>
              <div className="bg-white-A700 flex flex-1 flex-row gap-4 items-start justify-start px-1 w-full">
                <Img
                  className="h-[60px] w-12"
                  src={"images/img_icon_orange_500_60x48.svg"}
                  alt="icon"
                />
                <div className="flex flex-1 flex-col gap-1.5 items-start justify-start w-full">
                  <Text
                    className="text-2xl md:text-[22px] text-gray-900 sm:text-xl w-auto"
                    size="txtInterSemiBold24Gray900"
                  >
                    Σ–Network Finance
                  </Text>
                  <Text
                    className="leading-[160.00%] max-w-[288px] md:max-w-full text-gray-900_99 text-xl"
                    size="txtInterRegular20"
                  >
                    <>
                      Σ–Network Finance is committed to providing financial
                      services by integrating Σ–Network as payment methods to
                      meet users&#39; needs in pre-consumption, lending
                      and financing.
                    </>
                  </Text>
                </div>
              </div>
            </div>
          </div>

          <div className="bg-blue-A700_93 md:h-[574px] h-[604px] md:px-5 relative w-full" ref={agatRef}>
            <Img className={"absolute h-[77px] w-[77px] top-0 right-0 "}
                 src={"images_mobile/img_shapes_amber_a200.svg"} />
            <div
              className="absolute flex flex-col gap-5 inset-[0] items-center justify-start m-auto max-w-[375px] py-12 md:px-4 sm:px-2 px-[50px] w-full">
              <Text
                className="text-5xl sm:text-[32px] md:text-[40px] text-center text-white-A700 w-full"
                size="txtInterSemiBold48WhiteA700"
              >
                What is Σ–Network?
              </Text>
              <Text
                className="leading-[150.00%] text-2xl md:text-[16px] text-white-A700_cc sm:text-lg text-center"
                size="txtInterRegular24WhiteA700a2"
              >
                Σ–Network is the fundamental unit for benefit distribution in
                Σ–Network project.
                <br />
                <br />
                Σ–Network ensures equitable benefit distribution based on
                users&#39; contributions to its growth to foster participant
                enthusiasm, community prosperity, and the project&#39;s
                overall health. <br />
                <br />
                Σ–Network serves as both proof of contribution for diverse user
                roles within the project and an equity certificate, allowing
                users to reap corresponding rewards from the rapid growth of
                the Σ–Network project.
              </Text>
            </div>
          </div>

          <div
            className="bg-deep_purple-A400_05 flex flex-col gap-10 items-center justify-start md:px-10 sm:px-5 px-[150px] py-10 w-full"
            ref={earnAgatRef}
          >
            <Text
              className="text-5xl sm:text-[32px] md:text-[40px] text-center text-gray-900 w-full"
              size="txtInterSemiBold48"
            >
              How to Earn Σ–Network?
            </Text>
            <div className="flex flex-col gap-10 items-center justify-start max-w-[1300px] mx-auto w-full">
              <Text
                className="leading-[170.00%] text-2xl md:text-[16px] text-center text-gray-900_a2 sm:text-lg w-full"
                size="txtInterRegular24"
              >
                Σ–Network provides users with multiple ways to contribute
                to project development and obtain corresponding amounts of Σ–Network.
              </Text>
              <div className="flex flex-col items-start justify-between w-full">
                <List
                  className="flex flex-col gap-6 items-center w-full"
                  orientation="vertical"
                >
                  <div
                    className="bg-white-A700 flex flex-1 flex-col gap-4 h-[404px] md:h-auto items-center justify-start sm:px-5 px-6 py-4 rounded-[10px] w-full">
                    <Img
                      className="h-20 w-[72px]"
                      src={"images/img_user.svg"}
                      alt="user"
                    />
                    <Text
                      className="text-2xl md:text-[22px] text-center text-gray-900 sm:text-xl w-full"
                      size="txtInterSemiBold24Gray900"
                    >
                      Free Mining
                    </Text>
                    <Text
                      className="leading-[160.00%] max-w-[320px] md:max-w-full text-center text-gray-900_99 text-lg"
                      size="txtInterRegular20"
                    >
                      All users can mine Σ–Network regularly for free by installing
                      the Σ–Network App on their mobile devices.
                    </Text>
                  </div>
                  <div
                    className="bg-white-A700 flex flex-1 flex-col gap-4 h-[404px] md:h-auto items-center justify-start sm:px-5 px-6 py-4 rounded-[10px] w-full">
                    <Img
                      className="h-20 w-[72px]"
                      src={"images/img_iconinviter.svg"}
                      alt="iconinviter"
                    />
                    <Text
                      className="text-2xl md:text-[22px] text-center text-gray-900 sm:text-xl w-full"
                      size="txtInterSemiBold24Gray900"
                    >
                      Invite New Users
                    </Text>
                    <Text
                      className="leading-[160.00%] max-w-[320px] md:max-w-full text-center text-gray-900_99 text-lg"
                      size="txtInterRegular20"
                    >
                      Inviting new users to join the Σ–Network community
                      will enable you to continuously receive Σ–Network rewards and
                      rebates.
                    </Text>
                  </div>
                  <div
                    className="bg-white-A700 flex flex-1 flex-col gap-4 h-[404px] md:h-auto items-center justify-start sm:px-5 px-6 py-4 rounded-[10px] w-full">
                    <Img
                      className="h-20 w-[72px]"
                      src={"images/img_icon_red_a200_01.svg"}
                      alt="icon"
                    />
                    <Text
                      className="text-2xl md:text-[22px] text-center text-gray-900 sm:text-xl w-full"
                      size="txtInterSemiBold24Gray900"
                    >
                      Contribute to Ecosystem
                    </Text>
                    <Text
                      className="leading-[160.00%] max-w-[320px] md:max-w-full text-center text-gray-900_99 text-lg"
                      size="txtInterRegular20"
                    >
                      <>
                        Contributing time, computing power or purchasing to
                        Σ–Network&#39;s business ecosystem, such as
                        Σ–Network Store, will enable you to receive Σ–Network.
                      </>
                    </Text>
                  </div>
                  <div
                    className="bg-white-A700 flex flex-1 flex-col gap-4 h-[404px] md:h-auto items-center justify-start sm:px-5 px-6 py-4 rounded-[10px] w-full">
                    <Img
                      className="h-20 w-[72px]"
                      src={"images/img_thumbsup.svg"}
                      alt="thumbsup"
                    />
                    <div className="flex flex-col gap-4 items-center justify-start">
                      <Text
                        className="leading-[140.00%] text-2xl md:text-[22px] text-center text-gray-900 sm:text-xl w-full"
                        size="txtInterSemiBold24Gray900"
                      >
                        Participate in Community Activities
                      </Text>
                      <Text
                        className="leading-[160.00%] max-w-[320px] md:max-w-full text-center text-gray-900_99 text-lg"
                        size="txtInterRegular20"
                      >
                        Regularly held community activities provide users with
                        opportunities to earn additional Σ–Network.
                      </Text>
                    </div>
                  </div>
                  <div
                    className="bg-white-A700 flex flex-1 flex-col gap-4 h-[404px] md:h-auto items-center justify-start sm:px-5 px-6 py-4 rounded-[10px] w-full">
                    <Img
                      className="h-20 w-[72px]"
                      src={"images/img_user_red_a200_01.svg"}
                      alt="user"
                    />
                    <div className="flex flex-col gap-4 items-center justify-start">
                      <Text
                        className="leading-[140.00%] text-2xl md:text-[22px] text-center text-gray-900 sm:text-xl w-full"
                        size="txtInterSemiBold24Gray900"
                      >
                        Contribute to Project Growth
                      </Text>
                      <Text
                        className="leading-[160.00%] max-w-[320px] md:max-w-full text-center text-gray-900_99 text-lg"
                        size="txtInterRegular20"
                      >
                        Contributing to the growth of the Σ–Network
                        project through methods like staking, promotion, and
                        publicity enables you to receive additional Σ–Network.
                      </Text>
                    </div>
                  </div>
                  <div
                    className="bg-white-A700 flex flex-1 flex-col gap-4 items-center justify-start sm:px-5 px-6 py-4 rounded-[10px] w-full">
                    <Img
                      className="h-20 w-[72px]"
                      src={"images/img_facebook.svg"}
                      alt="facebook"
                    />
                    <div className="flex flex-col gap-4 items-center justify-start">
                      <Text
                        className="leading-[140.00%] text-2xl md:text-[22px] text-center text-gray-900 sm:text-xl w-full"
                        size="txtInterSemiBold24Gray900"
                      >
                        Participate in Governance Decisions
                      </Text>
                      <Text
                        className="leading-[160.00%] max-w-[320px] md:max-w-full text-center text-gray-900_99 text-lg"
                        size="txtInterRegular20"
                      >
                        Participating in community representative meetings and
                        proposing governance suggestions enable you to receive
                        additional rewards in Σ–Network.
                      </Text>
                    </div>
                  </div>
                </List>
              </div>
            </div>
          </div>

          <div className="flex pr-[43px] md:px-5 relative w-full">
            <div
              className="flex flex-col gap-10 items-start justify-end max-w-[1100px] my-auto md:px-10 sm:px-5 px-[150px] py-10 w-full">

              <div className="flex flex-col items-center justify-start ml-auto w-[87%]">
                <Img
                  className="h-[659px] md:h-auto object-cover w-full"
                  src={"images/img_2801.png"}
                  alt="2801"
                />
              </div>

              <Text
                className="text-5xl sm:text-[32px] md:text-[40px] text-gray-900 w-full text-center"
                size="txtInterSemiBold48"
              >
                Σ–Network Holders’ Privileges
              </Text>
              <Text
                className="leading-[170.00%] max-w-[800px] md:max-w-full text-2xl md:text-[16px] text-gray-900_a2 sm:text-lg"
                size="txtInterRegular24"
              >
                Each Σ–Network holder will be regarded as a shareholder of Σ–Network and enjoy various privileges based on the number of Σ–Network
                they hold.
              </Text>
              <div className="flex flex-col items-start justify-between w-full">
                <div className="flex flex-col gap-3 items-center justify-start w-full">
                  <div
                    className="flex flex-row gap-2 h-[244px] md:h-auto items-start justify-start px-1 w-full">
                    <Img
                      className="h-12 w-12"
                      src={"images/img_floatingicon.svg"}
                      alt="floatingicon"
                    />
                    <div className="flex flex-1 flex-col gap-1.5 items-start justify-start w-full">
                      <Text
                        className="text-2xl md:text-[20px] text-gray-900 sm:text-xl w-auto"
                        size="txtInterSemiBold24Gray900"
                      >
                        Earning Profits
                      </Text>
                      <Text
                        className="leading-[170.00%] max-w-[318px] md:max-w-full text-gray-900_a2 text-lg"
                        size="txtInterRegular20Gray900a2"
                      >
                        By continually acquiring new Σ–Network, each user can share
                        in the revenue of the Σ–Network platform,
                        regularly profiting from its growth.
                      </Text>
                    </div>
                  </div>
                  <div
                    className="flex flex-row gap-2 h-[244px] md:h-auto items-start justify-start px-1 w-full">
                    <Img
                      className="h-12 w-12"
                      src={"images/img_close_orange_500.svg"}
                      alt="close"
                    />
                    <div className="flex flex-1 flex-col gap-1.5 items-start justify-start w-full">
                      <Text
                        className="text-2xl md:text-[20px] text-gray-900 sm:text-xl w-full"
                        size="txtInterSemiBold24Gray900"
                      >
                        Shareholder Dividends
                      </Text>
                      <Text
                        className="leading-[170.00%] max-w-[318px] md:max-w-full text-gray-900_a2 text-lg"
                        size="txtInterRegular20Gray900a2"
                      >
                        Every Σ–Coin holder is considered a shareholder of the Σ–Network project, and receives
                        regular dividends, as a financial incentive.
                      </Text>
                    </div>
                  </div>
                  <div
                    className="flex flex-row gap-2 h-[244px] md:h-auto items-start justify-start px-1 w-full">
                    <Img
                      className="h-12 w-12"
                      src={"images/img_close_blue_a700_01.svg"}
                      alt="close"
                    />
                    <div className="flex flex-1 flex-col gap-1.5 items-start justify-start w-full">
                      <Text
                        className="text-2xl md:text-[20px] text-gray-900 sm:text-xl w-full"
                        size="txtInterSemiBold24Gray900"
                      >
                        Participate in Voting
                      </Text>
                      <Text
                        className="leading-[170.00%] max-w-[318px] md:max-w-full text-gray-900_a2 text-lg"
                        size="txtInterRegular20Gray900a2"
                      >
                        Σ–Coin holders can engage in governance decisions, where each participant has equal voting
                        rights to propose and vote on the direction of project.
                      </Text>
                    </div>
                  </div>
                  <div className="flex flex-row gap-2 h-[244px] md:h-auto items-start justify-start px-1 w-full">
                    <Img
                      className="h-12 w-12"
                      src={"images/img_user_orange_500.svg"}
                      alt="user"
                    />
                    <div className="flex flex-1 flex-col gap-1.5 items-start justify-start w-full">
                      <Text
                        className="text-2xl md:text-[20px] text-gray-900 sm:text-xl w-full"
                        size="txtInterSemiBold24Gray900"
                      >
                        Submit Proposals
                      </Text>
                      <Text
                        className="leading-[170.00%] max-w-[318px] md:max-w-full text-gray-900_a2 text-lg"
                        size="txtInterRegular20Gray900a2"
                      >
                        Σ–Network holders have the right to submit governance proposals as a hallmark of a decentralized
                        governance model, empowering stakeholders in the decision-making process.
                      </Text>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-col relative w-full" ref={roadmapRef}>
            <div className="md:h-auto h-auto mx-auto w-full">
              <Img
                className="h-[341px] w-full object-cover"
                src={"images_mobile/img_background.svg"}
                alt="background"
              />
              <div
                className="absolute flex flex-col gap-5 h-full inset-[0] items-center justify-start m-auto max-w-[375px] md:px-4 sm:px-2 px-[50px] py-10 w-full">
                <Text
                  className="leading-[140.00%] text-5xl sm:text-[32px] md:text-[40px] text-center text-white-A700 w-full"
                  size="txtInterSemiBold48WhiteA700"
                >
                  Σ–Network<br />
                  Roadmap
                </Text>
                <Text
                  className="leading-[140.00%] max-w-[375px] md:max-w-full text-2xl md:text-[16px] text-center text-white-A700_cc sm:text-lg"
                  size="txtInterRegular24WhiteA700a2"
                >
                  The Σ–Network blockchain project unfolds in three
                  pivotal stages: PMF (Product Market Fit) stage, testnet
                  stage, and mainnet stage, each tailored to achieve distinct
                  goals and key milestones.
                </Text>
              </div>
            </div>
            <div className={"flex flex-col gap-3.5 items-center justify-start px-4 py-4 w-full"}>
              <div className={"bg-white-A700 rounded-[5px]"}>
                <Accordion expanded={expanded === "panel1"} onChange={handleChange("panel1")}>
                  <AccordionSummary aria-controls="panel1d-content" id="panel1d-header"
                                    expandIcon={<Img className={"w-8 h-8"} src={"images_mobile/ic_arrow_down.svg"} />}>
                    <div className="flex flex-row gap-6 items-center justify-start w-full">
                      <Button
                        className="cursor-pointer font-semibold h-12 md:text-3xl sm:text-[28px] text-[32px] text-center w-12"
                        shape="round"
                        color="gray_100"
                        size="xs"
                        variant="fill"
                      >
                        1
                      </Button>
                      <Text
                        className="flex-1 text-2xl md:text-[22px] text-gray-900 sm:text-xl w-auto"
                        size="txtInterSemiBold24Gray900"
                      >
                        MVP Stage
                      </Text>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Text
                      className="leading-[170.00%] max-w-[294px] md:max-w-full text-base text-gray-900_99 ml-[75px]"
                      size="txtInterRegular16"
                    >
                      In the MVP (Minimum Viable Product) stage, the objective
                      is to swiftly create a basic product with essential
                      functionalities. This product will serve as an initial
                      demonstration of blockchain technology application,
                      highlighting the innovation and advantages of Σ–Network
                      .{" "}
                    </Text>
                  </AccordionDetails>
                </Accordion>
              </div>
              <div className={"bg-white-A700 rounded-[5px]"}>
                <Accordion expanded={expanded === "panel2"} onChange={handleChange("panel2")}>
                  <AccordionSummary aria-controls="panel2d-content" id="panel2d-header"
                                    expandIcon={<Img className={"w-8 h-8"} src={"images_mobile/ic_arrow_down.svg"} />}>
                    <div className="flex flex-row gap-6 items-center justify-start w-full">
                      <Button
                        className="cursor-pointer font-semibold h-12 md:text-3xl sm:text-[28px] text-[32px] text-center w-12"
                        shape="round"
                        color="gray_100"
                        size="xs"
                        variant="fill"
                      >
                        2
                      </Button>
                      <Text
                        className="flex-1 text-2xl md:text-[22px] text-gray-900 sm:text-xl w-auto"
                        size="txtInterSemiBold24Gray900"
                      >
                        PMF Stage
                      </Text>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Text
                      className="leading-[170.00%] max-w-[294px] md:max-w-full text-base text-gray-900_99 ml-[75px]"
                      size="txtInterRegular16"
                    >
                      In the PMF (Product-Market Fit) stage, we aim to progressively meet user expectations and
                      establish a fitting position in the market by optimizing product functions, enhance user
                      experience, and strive for a perfect alignment between the product and the market.{" "}
                    </Text>
                  </AccordionDetails>
                </Accordion>
              </div>
              <div className={"bg-white-A700 rounded-[5px]"}>
                <Accordion expanded={expanded === "panel3"} onChange={handleChange("panel3")}>
                  <AccordionSummary aria-controls="panel3d-content" id="panel3d-header"
                                    expandIcon={<Img className={"w-8 h-8"} src={"images_mobile/ic_arrow_down.svg"} />}>
                    <div className="flex flex-row gap-6 items-center justify-start w-full">
                      <Button
                        className="cursor-pointer font-semibold h-12 md:text-3xl sm:text-[28px] text-[32px] text-center w-12"
                        shape="round"
                        color="gray_100"
                        size="xs"
                        variant="fill"
                      >
                        3
                      </Button>
                      <Text
                        className="flex-1 text-2xl md:text-[22px] text-gray-900 sm:text-xl w-auto"
                        size="txtInterSemiBold24Gray900"
                      >
                        RRR Stage
                      </Text>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Text
                      className="leading-[170.00%] max-w-[294px] md:max-w-full text-base text-gray-900_99 ml-[75px]"
                      size="txtInterRegular16"
                    >
                      In the RRR stage (Retention, Revenue, Referral) , we enhance user retention, optimize experiences,
                      and build a business model. Diversified revenue sources ensure financial sustainability. An
                      incentive plan fosters user referrals, expanding our influence and creating a robust
                      ecosystem.{" "}
                    </Text>
                  </AccordionDetails>
                </Accordion>
              </div>
              <div className={"bg-white-A700 rounded-[5px]"}>
                <Accordion expanded={expanded === "panel4"} onChange={handleChange("panel4")}>
                  <AccordionSummary aria-controls="panel4d-content" id="panel4d-header"
                                    expandIcon={<Img className={"w-8 h-8"} src={"images_mobile/ic_arrow_down.svg"} />}>
                    <div className="flex flex-row gap-6 items-center justify-start w-full">
                      <Button
                        className="cursor-pointer font-semibold h-12 md:text-3xl sm:text-[28px] text-[32px] text-center w-12"
                        shape="round"
                        color="gray_100"
                        size="xs"
                        variant="fill"
                      >
                        4
                      </Button>
                      <Text
                        className="flex-1 text-2xl md:text-[22px] text-gray-900 sm:text-xl w-auto"
                        size="txtInterSemiBold24Gray900"
                      >
                        Community Growth
                      </Text>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Text
                      className="leading-[170.00%] max-w-[294px] md:max-w-full text-base text-gray-900_99 ml-[75px]"
                      size="txtInterRegular16"
                    >
                      In Σ–Network's Community Growth stage, the focus is on reinforcing governance mechanisms,
                      enhancing user participation, and fostering a decentralized, transparent system. Active guidance
                      and tools like community voting aim to achieve direct user involvement in shaping the project's
                      future, creating a vibrant ecosystem.{" "}
                    </Text>
                  </AccordionDetails>
                </Accordion>
              </div>
              <div className={"bg-white-A700 rounded-[5px]"}>
                <Accordion expanded={expanded === "panel5"} onChange={handleChange("panel5")}>
                  <AccordionSummary aria-controls="panel5d-content" id="panel5d-header"
                                    expandIcon={<Img className={"w-8 h-8"} src={"images_mobile/ic_arrow_down.svg"} />}>
                    <div className="flex flex-row gap-6 items-center justify-start w-full">
                      <Button
                        className="cursor-pointer font-semibold h-12 md:text-3xl sm:text-[28px] text-[32px] text-center w-12"
                        shape="round"
                        color="gray_100"
                        size="xs"
                        variant="fill"
                      >
                        5
                      </Button>
                      <Text
                        className="flex-1 text-2xl md:text-[22px] text-gray-900 sm:text-xl w-auto"
                        size="txtInterSemiBold24Gray900"
                      >
                        Scenarios Expansion
                      </Text>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Text
                      className="leading-[170.00%] max-w-[294px] md:max-w-full text-base text-gray-900_99 ml-[75px]"
                      size="txtInterRegular16"
                    >
                      In the Scenarios Expansion stage, Σ–Network integrates blockchain seamlessly into diverse
                      scenarios, offering users a range of services such as e-commerce, digital content, social
                      entertainment, online gaming, tools, and more. Our goal is to provide a diverse and convenient
                      service array, fostering comprehensive project development.{" "}
                    </Text>
                  </AccordionDetails>
                </Accordion>
              </div>
              <div className={"bg-white-A700 rounded-[5px]"}>
                <Accordion expanded={expanded === "panel6"} onChange={handleChange("panel6")}>
                  <AccordionSummary aria-controls="panel6d-content" id="panel6d-header"
                                    expandIcon={<Img className={"w-8 h-8"} src={"images_mobile/ic_arrow_down.svg"} />}>
                    <div className="flex flex-row gap-6 items-center justify-start w-full">
                      <Button
                        className="cursor-pointer font-semibold h-12 md:text-3xl sm:text-[28px] text-[32px] text-center w-12"
                        shape="round"
                        color="gray_100"
                        size="xs"
                        variant="fill"
                      >
                        6
                      </Button>
                      <Text
                        className="flex-1 text-2xl md:text-[22px] text-gray-900 sm:text-xl w-auto"
                        size="txtInterSemiBold24Gray900"
                      >
                        Ecosystem Building
                      </Text>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Text
                      className="leading-[170.00%] max-w-[294px] md:max-w-full text-base text-gray-900_99 ml-[75px]"
                      size="txtInterRegular16"
                    >
                      In the Ecosystem Building stage, users are encouraged to share content, goods, or services,
                      contributing value and receiving benefits. The goal is an ecosystem where users actively
                      contribute and share collective value, reaping rewards from project success.{" "}
                    </Text>
                  </AccordionDetails>
                </Accordion>
              </div>
              <div className={"bg-white-A700 rounded-[5px]"}>
                <Accordion expanded={expanded === "panel7"} onChange={handleChange("panel7")}>
                  <AccordionSummary aria-controls="panel7d-content" id="panel7d-header"
                                    expandIcon={<Img className={"w-8 h-8"} src={"images_mobile/ic_arrow_down.svg"} />}>
                    <div className="flex flex-row gap-6 items-center justify-start w-full">
                      <Button
                        className="cursor-pointer font-semibold h-12 md:text-3xl sm:text-[28px] text-[32px] text-center w-12"
                        shape="round"
                        color="gray_100"
                        size="xs"
                        variant="fill"
                      >
                        7
                      </Button>
                      <Text
                        className="flex-1 text-2xl md:text-[22px] text-gray-900 sm:text-xl w-auto"
                        size="txtInterSemiBold24Gray900"
                      >
                        Brand Enhancement
                      </Text>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Text
                      className="leading-[170.00%] max-w-[294px] md:max-w-full text-base text-gray-900_99 ml-[75px]"
                      size="txtInterRegular16"
                    >
                      In the Brand Enhancement stage, Σ–Network prioritizes brand building and global expansion.
                      This strategic focus aims for steady global promotion, emphasizing a strong brand presence and
                      expanding globally to solidify Σ–Network's position on the global stage.
                    </Text>
                  </AccordionDetails>
                </Accordion>
              </div>
              <div className={"bg-white-A700 rounded-[5px]"}>
                <Accordion expanded={expanded === "panel8"} onChange={handleChange("panel8")}>
                  <AccordionSummary aria-controls="panel8d-content" id="panel8d-header"
                                    expandIcon={<Img className={"w-8 h-8"} src={"images_mobile/ic_arrow_down.svg"} />}>
                    <div className="flex flex-row gap-6 items-center justify-start w-full">
                      <Button
                        className="cursor-pointer font-semibold h-12 md:text-3xl sm:text-[28px] text-[32px] text-center w-12"
                        shape="round"
                        color="gray_100"
                        size="xs"
                        variant="fill"
                      >
                        8
                      </Button>
                      <Text
                        className="flex-1 text-2xl md:text-[22px] text-gray-900 sm:text-xl w-auto"
                        size="txtInterSemiBold24Gray900"
                      >
                        Open Σ–Network Trading
                      </Text>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Text
                      className="leading-[170.00%] max-w-[294px] md:max-w-full text-base text-gray-900_99 ml-[75px]"
                      size="txtInterRegular16"
                    >
                      In the Open Trading stage, Σ–Network will strategically list Σ–Coins on major
                      cryptocurrency exchanges and promote the stable appreciation of Σ–Network through cooperation with
                      reputable exchanges, extensive market promotion, trading incentives, and expanded trading pairs,
                      etc.
                    </Text>
                  </AccordionDetails>
                </Accordion>
              </div>
              <div className={"bg-white-A700 rounded-[5px]"}>
                <Accordion expanded={expanded === "panel9"} onChange={handleChange("panel9")}>
                  <AccordionSummary aria-controls="panel9d-content" id="panel9d-header"
                                    expandIcon={<Img className={"w-8 h-8"} src={"images_mobile/ic_arrow_down.svg"} />}>
                    <div className="flex flex-row gap-6 items-center justify-start w-full">
                      <Button
                        className="cursor-pointer font-semibold h-12 md:text-3xl sm:text-[28px] text-[32px] text-center w-12"
                        shape="round"
                        color="gray_100"
                        size="xs"
                        variant="fill"
                      >
                        9
                      </Button>
                      <Text
                        className="flex-1 text-2xl md:text-[22px] text-gray-900 sm:text-xl w-auto"
                        size="txtInterSemiBold24Gray900"
                      >
                        Global Presence
                      </Text>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Text
                      className="leading-[170.00%] max-w-[294px] md:max-w-full text-base text-gray-900_99 ml-[75px]"
                      size="txtInterRegular16"
                    >
                      Σ–Network strategically focuses on global promotion and enhances the value of Σ–Network through
                      global local communities and business sectors. The project aims to build a strong global presence
                      using comprehensive marketing, social media and strategic partnerships.
                    </Text>
                  </AccordionDetails>
                </Accordion>
              </div>
            </div>
          </div>

          <div className="h-[466px] relative w-full">
            <div className="absolute h-[466px] inset-[0] justify-center m-auto w-full">
              <div className="bg-blue-A700_93 h-[466px] m-auto w-full"></div>
              <Img
                className="absolute h-[77px] object-cover right-[0] top-[0]"
                src={"images_mobile/img_shapes_amber_a200.svg"}
                alt="shapes_One"
              />
            </div>
            <div
              className="absolute flex flex-col gap-5 items-start justify-start max-w-[375px] md:px-6 sm:px-3.5 px-[100px] top-[11%] w-full">
              <Text
                className="leading-[140.00%] text-5xl sm:text-[32px] md:text-[40px] text-white-A700 text-center w-full"
                size="txtInterSemiBold48WhiteA700"
              >
                Global Access<br />
                at Zero Expense
              </Text>
              <Text
                className="leading-[140.00%] max-w-[370px] md:max-w-full text-2xl md:text-[16px] text-white-A700_cc sm:text-lg text-center"
                size="txtInterRegular24WhiteA700a2"
              >
                Σ–Network extends an open invitation to individuals across the globe, offering unrestricted access
                without financial barriers, thereby allowing users worldwide to participate freely in its blockchain
                ecosystem.
              </Text>
              <div className="flex flex-row items-center justify-between py-2.5 w-full">
                <Img
                  className="h-[51px] md:h-auto object-cover w-[161px]"
                  src={"images/img_appstore.png"}
                  alt="appstore"
                  onClick={() => {
                    handleClick("Install")
                  }}
                />
                <Img
                  className="h-[51px] md:h-auto object-cover w-[161px]"
                  src={"images/img_playstore.png"}
                  alt="playstore"
                  onClick={() => {
                    handleClick("Install")
                  }}
                />
              </div>
            </div>
          </div>

          <div
            className="flex md:flex-col flex-row md:gap-10 items-center justify-start md:px-10 sm:px-5 px-[150px] py-10 w-full">
            <div className="flex flex-1 flex-col gap-6 items-start justify-start sm:px-5 w-full">
              <div className="flex flex-row gap-[23px] items-center justify-start w-full">
                <div className="flex flex-col h-10 items-center justify-start rounded-lg w-10">
                  <Img
                    className="h-10 md:h-auto object-cover rounded-lg w-10"
                    src={"images/img_logo.png"}
                    alt="logo_One"
                  />
                </div>
                <Text
                  className="text-2xl md:text-[22px] text-center text-gray-900 sm:text-xl w-auto"
                  size="txtInterSemiBold24Gray900"
                >
                  Σ–Network
                </Text>
              </div>
              <Text
                className="leading-[160.00%] max-w-[338px] md:max-w-full text-gray-900_99 text-xl"
                size="txtInterRegular20"
              >
                Σ–Network is co-founded and orchestrated by a consortium of professionals distributed globally, who
                are united by shared convictions. It is governed by a Management Committee, legitimized by the Σ–Network
                Community Congress, with an excellent model of decentralized administration.
              </Text>
              <div className="flex flex-row gap-4 md:gap-3 items-center justify-start py-2.5 w-full">
                <Img
                  className="h-12 w-12"
                  src={"images/img_telegram_light_blue_600.svg"}
                  alt="telegram_One"
                  onClick={() => {
                    handleClick("Community")
                  }}
                />
                <Img
                  className="h-12 w-12"
                  src={"images/img_notify.png"}
                  alt="notice"
                  onClick={() => {
                    handleClick("Notice")
                  }}
                />
                <Img
                  className="h-12 w-12"
                  src={"images/ic_discord.svg"}
                  alt="discord"
                  onClick={()=>{
                    handleClick("Discord")
                  }}
                />
                <Img
                  className="h-12 w-12"
                  src={"images/img_twitter_x.png"}
                  alt="twitter"
                  onClick={() => {
                    handleClick("Twitter")
                  }}
                />
                <Img
                  className="h-12 w-12"
                  src={"images/img_facebook_indigo_600.svg"}
                  alt="facebook"
                  onClick={() => {
                    handleClick("FaceBook")
                  }}
                />
                {/*<Img*/}
                {/*  className="h-12 w-12"*/}
                {/*  src={"images/img_service.png"}*/}
                {/*  alt="icontwitter"*/}
                {/*  onClick={() => {*/}
                {/*    handleClick("Service")*/}
                {/*  }}*/}
                {/*/>*/}
              </div>
            </div>
            <div className="flex flex-1 flex-col gap-6 items-start justify-start sm:px-5 w-full" ref={contactRef}>
              <Text
                className="text-2xl md:text-[22px] text-gray-900 sm:text-xl w-full"
                size="txtInterSemiBold24Gray900"
              >
                Contact Info
              </Text>
              <div className="flex flex-row gap-4 items-center justify-start w-full">
                <Img
                  className="h-8 w-8"
                  src={"images/img_website.svg"}
                  alt="website"
                />
                <Link
                  href="https://agateschain.com"
                  className="text-blue-A700_99 text-xl w-auto"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Text size="txtInterRegular20BlueA70099">
                    https://agateschain.com
                  </Text>
                </Link>
              </div>
              <div className="flex flex-row gap-4 items-center justify-start w-full">
                <Img
                  className="h-8 w-8"
                  src={"images/img_email.svg"}
                  alt="email"
                />
                <Text
                  className="text-blue-A700_99 w-auto"
                  size="txtInterRegular20BlueA70099"
                >
                  mdjubaerh318@gmail.com
                </Text>
              </div>
              <div className="flex flex-row gap-4 items-center justify-start w-full">
                <Img
                  className="h-8 w-8"
                  src={"images/img_telegram.svg"}
                  alt="telegram"
                />
                <Link onClick={() => {
                  handleClick("Service");
                }}>
                  <Text
                    className="text-blue-A700_99 w-auto"
                    size="txtInterRegular20BlueA70099"
                  >
                    @Σ–Network Service
                  </Text>
                </Link>
              </div>
            </div>
            <div className="flex flex-col gap-4 items-center justify-start w-full">
              <Line className="bg-gray-900_63 h-px w-full" />
              <Text
                className="text-left text-gray-900_99 w-full"
                size="txtInterRegular16"
              >
                2024 © Σ–Network DAO All Rights Reserved.
              </Text>
            </div>
          </div>

        </div>
      </div>
    </>
  );
};

export default MobileAgate;
